// Here you can add other styles
.c-sidebar {
  color: #fff;
  background: var(--rq_primary);
}
.c-sidebar .c-sidebar-nav-link:hover, .c-sidebar .c-sidebar-nav-dropdown-toggle:hover {
  color: #fff;
  background: var(--rq_primary_dark);
}
a {
  color: var(--rq_primary);
}
.bg-primary {
  background-color: var(--rq_primary) !important;
}
.btn-primary:hover {
  color: #fff;
  background-color: var(--rq_primary);
  border-color: var(--rq_primary);
}
.btn-primary {
  color: #fff;
  background-color: var(--rq_primary_dark);
  border-color: var(--rq_primary_dark);
}
.image-gallery {
  position: relative;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  justify-items: center;
  gap: 20px;
  @media(max-width: 767px) {
    grid-template-columns: repeat(1, 1fr) !important;
  }
  @media(max-width: 1024px) {
    grid-template-columns: repeat(3, 1fr);
  }
}

.image-gallery img {
  max-width: 170px;
  max-height: 170px;
}

.image-gallery {
  div{
    width: 100%;
    height: 100%;
    text-align: center;
  }
  div[data-tippy-root] {
    width: 68px;
    height: 37px;
}
}

.trackhome-block {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 9999;
}
.trackhome-block::before {
    content: '';
    position: fixed;
    display: block;
    width: 100%;
    height: 100%;
    background:#008b633b;
    transform: scale(100);
    top: 0;}
.login-screen
{
  background-image: url('https://media.resourcequeue.com/image/23resource-queu-logo--1623239882.png');
  background-repeat: no-repeat;
  background-position: center;
  background-size: 70%;
}
.c-sidebar .c-sidebar-nav-dropdown-toggle:hover{
  background: #333535;
}
.c-sidebar .c-sidebar-nav-link:hover, .c-sidebar .c-sidebar-nav-dropdown-toggle:hover{
  background: #333535;
}
// .c-sidebar .c-sidebar-nav-link.c-active, .c-sidebar .c-active.c-sidebar-nav-dropdown-toggle{
//   color: #000000;
//   background: #ffffff;
// }
.toast-close-btn
{
  position: absolute;
  right: 12px;
  top: 18px;
  color: #00000042;
  font-size: 22px;
  display: flex;
  width: 20px;
  height: 20px;
  //background: #ffffff94;
  border-radius: 100%;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  font-weight: bold;
}
.img-progress
{
  position: absolute;
  top: 0;
  width: 100%;
  height: 28px;
}
.img-progress::before
{
  content: 'Uploading Image...';
  position: absolute;
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  bottom: 15px;
  color: #fff;
}
.img-frame
{
  position: relative;
  border: solid 1px #d8dbe0;
  padding: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 120px;
  width: 110px;
}
.img-frame img {
  height: 120px;
}
.div_block{
  text-align: center;
  padding: 3px 0px;
  background: #F6F5F8;
  border-radius: 20px;
  font-size: 13px;
  color: #045258;
  font-weight: 700;
}
.label{
  padding: 0px 0px 0px 23px;
  font-size: 16px;
}