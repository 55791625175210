:root {
  --pri-text-color: #045258;
}

/* .form-control-sm {
  border-radius: 20px !important;
  border: 1px solid #045258;
  padding: 15px;
} */
.table > tbody > tr > td {
  text-align: center;
}
.element {
  border-radius: 20px;
  /* border: 1px solid pink; */
  background: #f6f5f8;
  color: #045258;
  padding: 8px 0px;
  width: 100%;
}

.resource-list-img {
  border: 1px solid #104934;
  border-radius: 28px;
  object-fit: contain;
}

.resource-company-icon {
  width: 25px !important;
  height: 25px !important;
  margin-bottom: 8px;
  margin-left: 8px;
}

a.res-company:focus,
a.res-company:active,
a.res-company svg:focus {
  outline: none;
  border: none;
}

/* .admin-btn-outline .table th,
.table td {
  border: 0px;
} */
/* .table thead th {
  border: 0px;
} */
.editButton {
  border: 1px solid #555959 !important;
  border-radius: 70px !important;
  width: 76%;
}
.table-hover tbody tr:hover {
  /* background:#F6F5F8 */
}
.classbody {
  background: #d2d2d2;
  text-align: center;
  padding: 48px 22px 0px !important;
  cursor: pointer;
}
.classbody:hover {
  background: #e2e2e2;
  text-align: center;
  padding: 48px 22px 0px !important;
  cursor: pointer;
}
.details-text {
  color: #1a5650;
  font-weight: 700;
  font-size: 25px;
}
.details-text:hover {
  color: #2d7770;
  font-weight: 700;
  font-size: 25px;
}
.hr {
  margin: 0;
}
.cardmain {
  height: 385px;
  padding: 38px 0px 0px 36px !important;
  /* text-align: center; */
}
.icon-wrap {
  text-align: end;
}
.icon-wrap > svg {
  color: #1a5650;
  height: auto !important;
  width: 45px !important;
}
/* .classbody:hover{
    background: #1A5650;
    color: #ffffff;
} */

/* Company Details css */
.admin-label-wrapper {
  text-align: center;
}
.admin-label {
  padding: 5px 0px;
  font-size: 15px;
  font-weight: 500;
  color: #555959;
}

.admin-label-value {
  text-align: center;
  margin-bottom: 20px;
}

.admin-label-value.admin-company-address label {
  width: 95%;
  text-align: left;
  padding-left: 20px;
}

.title-addSeller > .css-xjwczm-control > .css-g1d714-ValueContainer {
  padding: 0 !important;
}

.admin-label-value label {
  width: 80%;
  font-size: 15px;
  font-weight: 600;
  padding: 7px 0px;
  border-radius: 17px;
  color: var(--pri-text-color);
  background-color: #e8e5ee;
  margin: 2px 0px;
}

.admin-input-field {
  width: 80%;
  font-size: 15px;
  font-weight: 600;
  padding: 7px 20px;
  border-radius: 17px;
  border: 2px solid transparent;
  color: var(--pri-text-color);
  background-color: #e8e5ee;
  margin: 2px 0px;
  outline: none;
}
.admin-label-value .admin-company-field {
  width: 95%;
  text-align: left;
  padding-left: 20px;
}

.admin-input-field::placeholder {
  text-align: center;
}

.admin-input-field.admin-company-field::placeholder {
  text-align: left;
}

.admin-btn-outline {
  border: 3.5px solid #c8cfd5;
  border-radius: 5px;
  margin: 5px;
  padding: 6px 20px;
  color: var(--pri-text-color);
  font-weight: 700;
}
.admin-btn-solid {
  border: 2px solid var(--pri-text-color);
  border-radius: 5px;
  margin: 5px;
  padding: 6px 18px;
  color: white;
  background-color: var(--pri-text-color);
  font-weight: 600;
}

.form-control:disabled,
.form-control[readonly],
.form-control {
  background-color: #ffffff !important;
  border-radius: 20px;
  color: #045258;
}

/* .custom-file-input::before {
  border: 1px solid #999;
} */
#cvUpload::-webkit-file-upload-button {
  background: inherit;
  border: none;
  border-right: 1px solid black;
  /* border-top-left-radius: 10px;
  border-bottom-left-radius: 10px; */
  /* height: 33px; */
}

.create_res_form_label {
  padding-bottom: 5px;
  margin: 0px 0px 15px 0px;
  border-bottom: 2px solid var(--pri-text-color);
}

.rq-label-required {
  font-size: 20px;
}

.css-g1d714-ValueContainer {
  height: 36px !important;
}

.rqVectorBg {
  border-radius: 10px 0 0 10px;
  padding: 60px 44px;
  background-image: url("/src/assets/icons/signup_vectorbg.png");
  background-position: 0 84%;
  background-size: 95%;
  background-repeat: no-repeat;
  background-color: #ecf3f3;
}
.rqSignupContainer {
  border-radius: 10px;
  display: grid;
  width: 100%;
  min-height: 766px;
  box-shadow: 0px 0px 4px 1px #9999993b;
  border: solid 1px #e6e6e6;
  background: #fff;
}
@media (max-width: 767px) {
  .rqVectorBg {
    padding: 30px 30px !important;
    background-position: right;
    background-size: 55%;
  }
}

.hover-zoom:hover {
  transform: scale(0.98);
}

.modal.c-no-sidebar .c-sidebar {
  display: none;
}
.c-no-sidebar {
  overflow-y: hidden !important; /* enable vertical scrolling */
  overflow-x: hidden !important; /* hide horizontal scrollbar */
  padding-right: 0px !important; /* remove right padding to prevent scrollbar overlap */
}

.grecaptcha-badge {
  visibility: hidden;
}
